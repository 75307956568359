import { createSlice } from '@reduxjs/toolkit'
import { api } from '../../api'
// Slice

const slice = createSlice({
  name: 'users',
  initialState: {
    error: false,
    isLoading: false,
    registerSuccess: false,
    signupSuccess: false,
    requestPasswordSuccess: false,
    resetPasswordSuccess: false,
    emailVerified: false,
    users: {},
  },
  reducers: {
    registerSuccess: (state) => {
      state.error = false;
      state.registerSuccess = true;
      state.isLoading = false;
    },
    eNewsSignupSuccess: (state) => {
      state.error = false;
      state.signupSuccess = true;
      state.isLoading = false;
    },
    verifyEmailSuccess: (state) => {
      state.error = false;
      state.emailVerified = true;
      state.isLoading = false;
    },
    requestPasswordSuccess: (state) => {
      state.requestPasswordSuccess = true;
      state.error = false;
      state.isLoading = false;
    },
    resetPasswordSuccess: (state) => {
      state.resetPasswordSuccess = true;
      state.error = false;
      state.isLoading = false;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    isLoading: (state) => {
      state.isLoading = true;
    },
  },
});

export default slice.reducer

// Actions

const {
  registerSuccess,
  eNewsSignupSuccess,
  verifyEmailSuccess,
  requestPasswordSuccess,
  resetPasswordSuccess,
  isLoading,
  hasError } = slice.actions

export const register = (data) => async dispatch => {

  try {
    dispatch(isLoading());
    await api.post('/accounts/register', data)
    dispatch(registerSuccess());
  } catch (e) {
    const { request, ...errorObject } = e.response;
    return dispatch(hasError(errorObject.data.message))
  }
};

export const eNewsSignup = (data) => async dispatch => {
  try {
    dispatch(isLoading());
    await api.post('/accounts/eNewsSignup', data)
    dispatch(eNewsSignupSuccess());
  } catch (e) {
    const { request, ...errorObject } = e.response;
    return dispatch(hasError(errorObject.data.message))
  }
};

export const verifyEmail = (data) => async dispatch => {

  try {
    dispatch(isLoading());
    await api.post('/accounts/verify-email', data);
    dispatch(verifyEmailSuccess());
  } catch (e) {
    const { request, ...errorObject } = e.response;
    return dispatch(hasError(errorObject.data.message))
  }
};

export const requestPassword = (data) => async dispatch => {

  try {
    dispatch(isLoading());
    await api.post('/accounts/forgot-password', data);
    dispatch(requestPasswordSuccess());
  } catch (e) {
    const { request, ...errorObject } = e.response;
    return dispatch(hasError(errorObject.data.message))
  }
};

export const resetPassword = (data) => async dispatch => {

  try {
    dispatch(isLoading());
    await api.post('/accounts/reset-password', data);
    dispatch(resetPasswordSuccess());
  } catch (e) {
    const { request, ...errorObject } = e.response;
    return dispatch(hasError(errorObject.data.message))
  }
};